<template>
  <div
    class="new-request-container"
    :class="
      showQuickSelection && stepperValue === 1 && audience ? 'd-flex' : ''
    "
  >
    <div
      :class="
        showQuickSelection && stepperValue === 1 && audience
          ? 'quick-panel-active'
          : ''
      "
    >
      <div v-if="!isReqSubmitted" class="stepper-wrapper">
        <div
          class="back-btn"
          v-if="stepperValue > 1"
          @click="stepperValue = stepperValue - 1"
          :style="{ position: 'absolute', top: '20px', left: `${backPos}` }"
        >
          &lt; {{ $t('common.back') }}
        </div>
        <div class="stepper-container">
          <v-stepper alt-labels v-model="stepperValue">
            <v-stepper-header>
              <v-stepper-step
                step=""
                id="op-context-step"
                :complete="stepperValue > 1"
                :color="'#21a7e0'"
              >
                {{ $t('build.steps[0]') }}
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                id="op-preferences-step"
                step=""
                :complete="stepperValue > 2"
                :color="'#21a7e0'"
              >
                {{ $t('overnightPresentations.files') }}
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                step=""
                id="op-files-step"
                :complete="stepperValue > 3"
                :color="'#21a7e0'"
              >
                {{ $t('overnightPresentations.preferneces') }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <template
          v-if="
            (showQuickSelection || !leftSideBarStatus) && stepperValue === 1
          "
        >
          <v-tooltip
            top
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                class="filter-btn"
                :class="toolType === 'audience' ? 'filter-btn-active' : ''"
                @click="handleQuickSelection('audience')"
                data-pendo-id="new-request-audience-btn"
                v-bind="attrs"
                v-on="on"
              >
                <img src="/assets/img/audience.svg" alt />
                Audience
              </button>
            </template>
            <span>{{ getCurrentAudienceName }}</span>
          </v-tooltip>
        </template>
        <template
          v-if="!showQuickSelection && leftSideBarStatus && stepperValue === 1"
        >
          <button
            class="filter-btn"
            :class="toolType === 'audience' ? 'filter-btn-active' : ''"
            :disabled="!audience"
            data-pendo-id="new-request-audience-btn"
            @click="handleQuickSelection('audience')"
          >
            <img src="/assets/img/audience.svg" alt />
            <span class="aud-name">Audience: {{ getCurrentAudienceName }}</span>
          </button>
        </template>
      </div>
      <div class="new-request-contents">
        <div class="new-request-content" v-if="!isReqSubmitted">
          <div class="context-container" v-if="stepperValue === 1">
            <div class="context-cont">
              <div class="define-context-header">
                {{ $t('build.step1.title') }}
              </div>
              <div class="define-context-subheader">
                {{ $t('overnightPresentations.virbage') }}
              </div>
              <div class="presentation-name-wrapper">
                <div class="presentation-name-label">
                  {{ $t('overnightPresentations.prezentationName') }}
                </div>
                <v-text-field
                  :placeholder="
                    $t('build.step1.prezentationNameFieldPlaceholder')
                  "
                  v-model="opName"
                  id="op-name"
                  data-pendo-id="op-name"
                  @focus="handleStartEventTrigger"
                  required
                  :rules="nameRules"
                />
              </div>
              <div
                class="opSensitiveFieldsContainer"
                v-if="isOpSensitiveFieldsEnabled === true"
              >
                <div class="targetForumLabel">
                  {{ $t('overnightPresentations.myTargetForum') }}
                </div>
                <div class="forumRadioOptions">
                  <div class="forumTypes" data-pendo-id="targetForumControl">
                    <v-radio-group v-model="forum">
                      <v-radio
                        v-for="(option, i) in ['External', 'Internal']"
                        :key="i"
                        :label="option"
                        :value="option"
                        @change="changeTargetForum(option)"
                      />
                    </v-radio-group>
                  </div>
                  <div class="externalGroup" v-if="forum === 'External'">
                    <v-radio-group v-model="forumExt">
                      <v-radio
                        v-for="(optExt, i) in sensitiveFieldsForums.external"
                        :key="i"
                        :label="optExt.name"
                        :value="optExt.name"
                        @change="forumExt = optExt.name"
                      />
                    </v-radio-group>
                    <div class="forumError" v-if="forumExt === ''">
                      {{ $t('overnightPresentations.forumTypeRequired') }}
                    </div>
                  </div>
                  <div class="internalGroup" v-if="forum === 'Internal'">
                    <v-radio-group v-model="forumInt">
                      <v-radio
                        v-for="(optInt, i) in sensitiveFieldsForums.internal"
                        :key="i"
                        :label="optInt.name"
                        :value="optInt.name"
                        @change="forumInt = optInt.name"
                      />
                    </v-radio-group>
                    <div class="forumError" v-if="forumInt === ''">
                      {{ 'Forum type is required' }}
                    </div>
                  </div>
                </div>
                <div
                  class="otherForumSelected"
                  v-if="forumExt === 'Other' || forumInt === 'Other'"
                >
                  <v-text-field
                    :placeholder="
                      $t('overnightPresentations.enterForumNamePlaceholder', {
                        forumType:
                          forum === 'External'
                            ? $t('overnightPresentations.external')
                            : $t('overnightPresentations.internal'),
                      })
                    "
                    v-model="otherForumName"
                    id="op-sensitive-field-forum-other"
                    required
                    :rules="otherForumRules"
                  />
                </div>
                <div class="bussinessRationaleField">
                  <div class="rationaleLabel">
                    <div class="ratioanleLabelText">
                      {{ $t('overnightPresentations.businessRationale') }}
                    </div>
                    <div>
                      <v-tooltip
                        top
                        max-width="250"
                        content-class="tooltip-content"
                        fixed
                        open-delay="500"
                        close-delay="300"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="icon"
                            v-text="`mdi-information-outline`"
                          />
                        </template>
                        <span>
                          {{
                            $t(
                              'overnightPresentations.seviceRecommendedToSeniorAudiences',
                            )
                          }}
                        </span>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-text-field
                    :placeholder="$t('overnightPresentations.businessReason')"
                    v-model="businessRationale"
                    data-pendo-id="businessRationale"
                    required
                    :rules="rationaleRules"
                    @focus="handleStartEventTrigger"
                    id="businessRationale"
                  />
                </div>
              </div>
              <div class="primary-audience-wrapper">
                <div class="primary-audience-label">
                  {{ $t('build.step1.audianceFieldLabel') }}
                  <div>
                    <v-tooltip
                      top
                      max-width="250"
                      content-class="tooltip-content"
                      fixed
                      open-delay="500"
                      close-delay="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="icon"
                          v-text="`mdi-information-outline`"
                        />
                      </template>
                      <span>
                        {{ $t('build.step1.audianceLabelTooltip') }}
                      </span>
                    </v-tooltip>
                  </div>
                </div>
                <AudienceSelectorTypesense
                  v-model="audience"
                  :isTextFieldStyle="true"
                  class="no-padding"
                  origin="OP"
                  @focus="handleStartEventTrigger($event)"
                  :placeholder="$t('build.step1.audianceFieldPlaceholder')"
                  @change="handleAudienceSelect($event)"
                  @add="handleAddAudience($event)"
                />
              </div>
              <div class="presentation-type-wrapper">
                <div class="presentation-type-label">
                  {{ $t('build.step1.storylineFieldLabel') }}
                  ({{ $t('overnightPresentations.optional') }})
                </div>
                <StorylineDropdown
                  ref="storylineSelector"
                  v-model="presentationType"
                  :isTextFieldStyle="true"
                  class="no-padding"
                  origin="OP"
                  :audienceStoryPref="audienceStoryPref"
                  @change="handleStorylineSelect($event)"
                  :isCustom="false"
                  placeholder="Search by type or build your own prezentation"
                  :handleCustomSelectClick="handleCustomSelectClick"
                  :handleManageStorylines="handleManageStorylines"
                  :disabled="!audience"
                />
              </div>
              <div
                class="presentation-name-wrapper"
                v-if="presentationType && presentationType.type === 'Other'"
              >
                <div class="presentation-name-label">
                  {{ $t('overnightPresentations.storyLineName') }}
                </div>
                <v-text-field
                  :placeholder="
                    $t('overnightPresentations.storyLineNamePlaceholder')
                  "
                  v-model="customStorylineName"
                  id="op-storyline-name"
                />
              </div>
              <div class="action-wrapper" style="text-align: center">
                <v-btn
                  class="primary-button"
                  color="#21a7e0"
                  rounded
                  width="135"
                  height="48"
                  @click="handleFirstStepNextClick"
                  :disabled="disableNextButton"
                  id="next-btn"
                  data-pendo-id="next-btn"
                  style="text-transform: none !important"
                >
                  {{ $t('common.next') }}
                </v-btn>
              </div>
            </div>
            <div class="fingerprint-button">
              <div class="expand-button">
                <a @click="toggleShowFingerprint">
                  <v-tooltip
                    bottom
                    max-width="300"
                    content-class="tooltip-content"
                    fixed
                  >
                    <span>{{
                      $t('overnightPresentations.viewPreferences')
                    }}</span>
                  </v-tooltip>
                </a>
              </div>
            </div>
          </div>
          <div class="files-container" v-if="stepperValue === 2">
            <div class="define-context-header">
              {{ $t('overnightPresentations.addYourFiles') }}
            </div>
            <div class="files-desc">
              <div class="files-subheader">
                {{ $t('overnightPresentations.uploadFiles') }}
              </div>
              <div>{{ $t('overnightPresentations.provideDocumets') }}</div>
              <div>
                {{ $t('overnightPresentations.confidentiality') }}
              </div>
            </div>
            <div class="upload-container">
              <div
                class="local-files-wrapper"
                :class="{
                  highlight: localFiles.length,
                }"
              >
                <div class="header">
                  {{ $t('overnightPresentations.localFiles') }}
                </div>
                <img :src="'/assets/img/local-files.png'" />
                <div class="desc">
                  <div>{{ $t('overnightPresentations.filesUpload') }}</div>
                  <div>
                    <span>
                      {{ $t('overnightPresentations.maximumFileSize') }}
                      <v-tooltip
                        right
                        max-width="300"
                        content-class="tooltip-content"
                        fixed
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="icon-title"
                            v-text="`mdi-information-outline`"
                            :style="{ 'font-size': '16px' }"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>
                          {{ $t('overnightPresentations.fileType') }}
                        </span>
                      </v-tooltip>
                    </span>
                  </div>
                </div>
                <div class="user-input">
                  <div class="label">
                    {{ $t('overnightPresentations.addAsManyAsYouNeed') }}
                  </div>
                  <div class="file-chips-wrapper">
                    <template v-if="localFiles.length">
                      <div
                        class="file-chip"
                        :class="{
                          'size-error': lFile.size / 1024 / 1024 > 200,
                        }"
                        v-for="(lFile, i) in localFiles"
                        :key="i"
                        :style="{
                          height: `${getFileItemsHeight(lFile)}`,
                        }"
                      >
                        <div class="fileNameWrapper">
                          {{ lFile.fileName ? lFile.fileName : lFile.name }}
                        </div>
                        <v-icon :color="'#7B7B7B'" @click="removeFile(i)"
                          >mdi-close-circle-outline</v-icon
                        >
                      </div>
                    </template>
                    <div class="file-chip-placeholder" v-else>
                      {{ $t('overnightPresentations.filesWillAppear') }}
                    </div>
                  </div>
                </div>
                <div class="file-upload-action">
                  <label for="file-upload" class="custom-file-upload">
                    {{ $t('overnightPresentations.selectFiles') }}
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    :accept="`${allowedUploadTypes.join(', ')}`"
                    @change="previewFiles"
                    multiple
                  />
                </div>
              </div>
              <div
                class="cloud-files-wrapper"
                :class="{ highlight: sharedLinks.trim().length }"
              >
                <div class="header">
                  {{ $t('overnightPresentations.cloudlinks') }}
                </div>
                <img :src="'/assets/img/cloud-links.png'" />
                <div class="desc">
                  <div>{{ $t('overnightPresentations.pasteLinks') }}</div>
                  <div>
                    {{ $t('overnightPresentations.filesLargerThan200MB') }}
                  </div>
                </div>
                <div class="user-input">
                  <div class="label">
                    {{ $t('overnightPresentations.shareableLinkInNewLink') }}
                  </div>
                  <v-textarea
                    v-model="sharedLinks"
                    class="share-links"
                    solo
                    :rules="linkRules"
                    :label="$t('overnightPresentations.pasteLinksHere')"
                    :no-resize="true"
                  ></v-textarea>
                </div>
                <div
                  :class="`${
                    sharedLinks.length > 0 && !sharedAccess
                      ? 'check-access-error'
                      : 'check-access'
                  }`"
                >
                  <v-checkbox
                    v-model="sharedAccess"
                    :hide-details="true"
                    :label="
                      $t('overnightPresentations.sharedAccessWithPrezentium')
                    "
                  ></v-checkbox>
                  <span class="guide-link" @click="handleHowtoShareAccess">{{
                    $t('overnightPresentations.howToShareAccess')
                  }}</span>
                </div>
              </div>
            </div>
            <div class="instructions-container files-desc">
              <div class="files-subheader">
                {{ $t('overnightPresentations.instructions') }}
              </div>
              <div class="instructions-subheader-desc">
                {{ $t('overnightPresentations.guidelines') }}
              </div>
              <v-textarea
                v-model="instructions"
                class="instructions-area"
                solo
                :label="$t('overnightPresentations.example')"
                :no-resize="true"
              ></v-textarea>
            </div>
            <div
              class="step2NextCTA"
              :style="{
                display: 'flex',
                'justify-content': 'center',
              }"
            >
              <v-btn
                class="primary-button"
                color="#21a7e0"
                rounded
                width="135"
                height="48"
                @click="handleStepTwoNextClick"
                :disabled="disableNextButton"
                id="next-btn"
                data-pendo-id="step-two-next-btn"
                :style="{
                  'text-transform': 'none !important',
                  margin: '0px 0px 24px 0px',
                }"
              >
                {{ $t('common.next') }}
              </v-btn>
            </div>
          </div>
          <div class="preference-container" v-if="stepperValue === 3">
            <div class="define-context-header">
              {{ $t('overnightPresentations.provideAdditionalPreferences') }}
            </div>
            <div class="template-heading">
              {{ $t('overnightPresentations.template') }}
            </div>
            <div>
              <v-radio-group
                class="pt-0 template-radio"
                data-pendo-id="opTemplatePreferences"
                v-model="addTemplateBy"
              >
                <v-radio value="deck" color="#20a7e0">
                  <span slot="label"
                    >{{ $t('overnightPresentations.useFromDeck') }}
                  </span>
                </v-radio>
                <v-radio value="own" color="#20a7e0">
                  <span slot="label"
                    >{{ $t('overnightPresentations.uploadYourOwnTemplate') }}
                  </span>
                </v-radio>
                <div class="upload-file" v-if="addTemplateBy === 'own'">
                  <v-file-input
                    v-model="ownTemplate"
                    :label="$t('overnightPresentations.selectFile')"
                    accept=".ppt, .pptx"
                  ></v-file-input>
                </div>
                <v-radio value="company" color="#20a7e0">
                  <span slot="label"
                    >{{ $t('overnightPresentations.selectCompanyTemplate') }}
                  </span>
                </v-radio>
              </v-radio-group>
              <div class="search-template" v-if="addTemplateBy === 'company'">
                <div class="templates-op-search-bar">
                  <v-text-field
                    :value="templateSearchTerm"
                    append-icon=""
                    single-line
                    hide-details
                    :placeholder="
                      $t('overnightPresentations.searchByTemplateCode')
                    "
                    class="templates-op-search-bar__input pl-4"
                    @input="(e) => handleTemplateOpSearchChange(e)"
                    @focus="searchWithTextBoxActive = true"
                    @blur="searchWithTextBoxActive = false"
                  >
                    <template #append>
                      <v-icon
                        class="icon"
                        v-text="`mdi-close`"
                        @click="clearTextFieldSearch"
                        name="prez-cross-icon"
                        color="#21a7e0"
                        v-show="templateSearchTerm.length > 0"
                      />
                      <v-icon
                        class="icon"
                        v-text="`mdi-magnify`"
                        name="prez-magnify-glass"
                        :color="
                          searchWithTextBoxActive === true
                            ? '#21a7e0'
                            : 'inherit'
                        "
                      />
                    </template>
                  </v-text-field>
                </div>
                <div v-if="templateSelected" class="searched-theme">
                  <v-tooltip
                    top
                    max-width="200"
                    content-class="tooltip-content"
                    fixed
                  >
                    <template v-slot:activator="{ on }">
                      <div class="template-title" v-on="on">
                        {{ templateSelected.name }}
                      </div>
                    </template>
                    <span>{{ templateSelected.name }}</span>
                  </v-tooltip>
                  <v-icon class="close-icon" @click="handleClose()"
                    >mdi-close</v-icon
                  >
                  <div class="thumbnailsRow">
                    <div class="available-theme-img">
                      <img :src="templateSelected.thumbnailURL" alt />
                    </div>
                    <div class="available-theme-img">
                      <img
                        :src="
                          templateSelected.sampleSlide
                            ? templateSelected.sampleSlideURL
                            : templateSelected.thumbnailURL
                        "
                        alt
                      />
                    </div>
                  </div>
                </div>
                <div :style="{ margin: '0px 0px 24px 0px' }">
                  <div class="suggested-title">
                    {{ $t('overnightPresentations.suggestedCompanyTemplates') }}
                  </div>
                  <div
                    class="template-grid"
                    :style="{ 'grid-template-columns': `${themesFrames}` }"
                    v-if="
                      themesFilteredWithSearch &&
                      themesFilteredWithSearch.length > 0
                    "
                  >
                    <div
                      v-for="(item, index) in [...themesFilteredWithSearch]"
                      class="suggested-theme"
                      :key="index"
                      @click="handleClickTheme(item)"
                    >
                      <v-tooltip
                        top
                        max-width="200"
                        content-class="tooltip-content"
                        fixed
                      >
                        <template v-slot:activator="{ on }">
                          <div class="template-title" v-on="on">
                            {{ item.name }}
                          </div>
                        </template>
                        <span>{{ item.name }}</span>
                      </v-tooltip>
                      <div class="thumbnailsRow">
                        <div class="available-theme-img">
                          <img :src="item.thumbnailURL" alt />
                        </div>
                        <div class="available-theme-img">
                          <img
                            :src="
                              item.sampleSlide
                                ? item.sampleSlideURL
                                : item.thumbnailURL
                            "
                            alt
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="noTemplatesFound" v-else>
                    {{ $t('overnightPresentations.noMatchesForYourSearch') }}
                  </div>
                </div>
              </div>
              <div class="audience-preference">
                <div class="reminder-heading">
                  {{
                    $t('overnightPresentations.hereAreYourAudiencePreferences')
                  }}
                </div>
                <div class="reminder-cont" data-pendo-id="select-op-audience">
                  <span class="reminder-heading2">
                    {{ $t('build.step1.audianceFieldLabel') }}</span
                  >
                  <v-tooltip
                    top
                    max-width="300"
                    content-class="tooltip-content"
                    fixed
                    open-delay="500"
                    close-delay="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="icon-title info-icon"
                        v-bind="attrs"
                        v-on="on"
                        v-text="`mdi-information-outline`"
                      />
                    </template>
                    <span>
                      {{ $t('build.step1.audianceLabelTooltip') }}
                    </span>
                  </v-tooltip>
                </div>
                <AudienceSelectorTypesense
                  v-model="audience"
                  :isTextFieldStyle="true"
                  class="no-padding"
                  @focus="handleStartEventTrigger($event)"
                  :placeholder="$t('build.step1.audianceFieldPlaceholder')"
                  @change="handleAudienceSelect($event)"
                  @add="handleAddAudience($event)"
                />
              </div>
            </div>
            <div
              class="custom-preference-container"
              data-pendo-id="op-design-preference"
            >
              <div class="pref-heading1">
                {{ $t('overnightPresentations.selectedTheFollowingDesigns') }}
              </div>
              <div class="custom-preferencess">
                <div class="single-preference image-pref">
                  <h3 class="single-pref-heading">
                    {{ $t('overnightPresentations.image') }}
                  </h3>
                  <div
                    v-for="(item, index) in preferences.imagePreferences"
                    :key="index"
                    @click="selectedPreference.imagePreferences = item.value"
                  >
                    <div class="single-sub-heading">{{ item.name }}</div>
                    <img
                      :src="item.img"
                      :class="
                        selectedPreference.imagePreferences === item.value
                          ? 'image-selected'
                          : 'image-not-selected'
                      "
                    />
                  </div>
                </div>
                <div class="separator" />
                <div class="single-preference color-pref">
                  <h3 class="single-pref-heading">
                    {{ $t('overnightPresentations.color') }}
                  </h3>
                  <div
                    v-for="(item, index) in preferences.colorPreferences"
                    :key="index"
                    @click="selectedPreference.colorPreferences = item.value"
                  >
                    <div class="single-sub-heading">{{ item.name }}</div>
                    <img
                      :src="item.img"
                      :class="
                        selectedPreference.colorPreferences === item.value
                          ? 'image-selected'
                          : 'image-not-selected'
                      "
                    />
                  </div>
                </div>
                <div class="separator" />
                <div class="single-preference illustration-pref">
                  <h3 class="single-pref-heading">
                    {{ $t('overnightPresentations.illustration') }}
                  </h3>
                  <div
                    v-for="(item, index) in preferences.illustrationPreferences"
                    :key="index"
                    @click="
                      selectedPreference.illustrationPreferences = item.value
                    "
                  >
                    <div class="single-sub-heading">{{ item.name }}</div>
                    <img
                      :src="item.img"
                      :class="
                        selectedPreference.illustrationPreferences ===
                        item.value
                          ? 'image-selected'
                          : 'image-not-selected'
                      "
                    />
                  </div>
                </div>
                <div class="separator" />
                <div class="single-preference data-visualization-pref">
                  <h3 class="single-pref-heading">
                    {{ $t('overnightPresentations.dataVisualisation') }}
                  </h3>
                  <div
                    v-for="(item, index) in preferences.dataPreferences"
                    :key="index"
                    @click="selectedPreference.dataPreferences = item.value"
                  >
                    <div class="single-sub-heading">{{ item.name }}</div>
                    <img
                      :src="item.img"
                      :class="
                        selectedPreference.dataPreferences === item.value
                          ? 'image-selected'
                          : 'image-not-selected'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="add-to-prezentations">
              <v-checkbox
                v-if="addToLibraryEnabled"
                color="#21a7e0"
                v-model="isAddedToPrezentation"
                :label="$t('overnightPresentations.addACopyToMyFinalDeck')"
              ></v-checkbox
              ><v-tooltip
                right
                max-width="220"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
                v-if="addToLibraryEnabled"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon-title info-icon"
                    v-bind="attrs"
                    v-on="on"
                    v-text="`mdi-information-outline`"
                  />
                </template>
                <span>
                  {{ $t('overnightPresentations.completedDeck') }}
                </span>
              </v-tooltip>
            </div>
            <div
              class="action-wrapper"
              :style="{ 'text-align': 'center', margin: '0px 0px 24px 0px' }"
            >
              <v-btn
                class="primary-button"
                color="#21a7e0"
                rounded
                width="135"
                height="48"
                id="next-btn"
                style="text-transform: none !important"
                @click="submitRequest"
                data-pendo-id="op-submit-new-request"
                :disabled="
                  (addTemplateBy === 'own' && !ownTemplate) ||
                  (addTemplateBy === 'company' && !templateSelected)
                "
                :loading="loading || loadingAudPrefs"
              >
                {{ $t('common.submit') }}
              </v-btn>
            </div>
          </div>
        </div>
        <div class="submit-request-content" v-else>
          <div class="heading">
            {{ $t('overnightPresentations.thankYouForSubmitting') }}
          </div>
          <img
            class="req-submitted-banner"
            :src="'/assets/img/overnight-prezentations/op-request-received.png'"
          />
          <div>
            {{ $t('overnightPresentations.recievedYourRequest') }}<br />
            {{ $t('overnightPresentations.trackYourRequest') }}
          </div>
          <div>
            {{ $t('overnightPresentations.chargeForThisRequest') }}
          </div>
          <v-btn
            class="primary-button"
            color="#21a7e0"
            rounded
            width="220"
            height="48"
            id="next-btn"
            style="text-transform: none !important"
            @click="submitAnother"
            :disabled="disableNewReq"
          >
            {{ $t('overnightPresentations.submitAnotherRequest') }}
          </v-btn>
        </div>
      </div>
    </div>
    <ConfirmationDialog
      :show="showDialog"
      :on-primary-click="onDialogClose"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogClose"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="$t('fingerprint.groupInsights.yesSure')"
      :title="$t('fingerprint.groupInsights.areYouSure')"
      :text="$t('fingerprint.groupInsights.dialogText')"
    />
    <QuickSelectionCntr
      class="quick-panel"
      v-if="showQuickSelection && stepperValue === 1 && audience"
      :toolType="toolType"
      :audience="audience"
      @close="handleQuickSelection"
    >
      <OpAdvancedFilters />
    </QuickSelectionCntr>
  </div>
</template>
<script>
import { debounce, chunk, orderBy, snakeCase } from 'lodash';
// import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import usersApi from '../../../API/users-api';
import { getFileURL } from '@/utils/calyrex';
import {
  getStorylines,
  submitOPRequest,
  getFingerprint,
  getFingerprintSlides,
  getUserCompanyInfo,
  getFeatureUsage,
  postFeatureUsage,
  uploadOPFile,
  uploadChunks,
} from '../../../utils/api-helper';
import AudienceSelectorTypesense from '../../common/AudienceSelectorTypesense.vue';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import ReminderDialog from '../MyPrezentation/ReminderDialog.vue';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import {
  DEDUCTIVE_PRINTS,
  INDUCTIVE_PRINTS,
  // getAllPrezentationOutline,
  // getCustomPrezentationOutline,
} from '../NewPrezentation/getPrezentationOutline';
import AddingMoreTemplateDetail from '../Profile/AddingMoreTemplateDetail.vue';
// import FingerprintMenu from './FingerprintMenu.vue';
import HowToShareAccess from './HowToShareAccess.vue';
import Upload from './Upload.vue';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';
import { trackTimeSpentEvents } from '../../common/Analytics/TimeSpentEvents.js';
import {
  TD_CHECKED,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_PLACEMENT,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_UNCHECKED,
  TD_OVERVIEW,
  TD_NEW_REQUEST,
  TD_INSTRUCTIONS,
  TD_TEMPLATE,
  TD_SHARED_FILE_ACCESS,
  TD_ADD_COPY_FINAL_DECK,
  TD_AUD,
  NA,
  TD_EVENT_START,
  TD_EVENT_END,
  TD_TIME_SPENT,
  TD_NUMBEROFSLIDES,
  TD_TIME_SPENT_IN_SECS,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import StorylineDropdown from '../../common/StorylineDropdown.vue';
import { removeSpecialCharacters } from '../../utils';
import QuickSelectionCntr from '../../common/QuickSelectionTools/QuickSelectionCntr.vue';
import EventBus from '../../common/event-bus';

export default {
  name: 'NewRequest',
  data() {
    return {
      showDialog: false,
      stepperValue: 1,
      showFingerprint: true,
      eventStart: null,
      audienceStoryPref: '',
      isAddedToPrezentation: true,
      opName: '',
      storylineType: null,
      tabOrigin: '',
      toolType: null,
      showQuickSelection: false,
      templateSelection: {
        deck: 'Use template from the deck uploaded in the previous step',
        own: 'Upload your own template or style guide',
        company: 'Select a complete template',
      },
      searchTerm: '',
      linkRules: [
        (v) =>
          this.isValidHttpUrl(v) ||
          v.length <= 0 ||
          'Oops! One or more of the entered links are invalid',
      ],
      preferences: {
        imagePreferences: [
          {
            name: 'Incorporate images',
            img: '/assets/img/overnight-prezentations/preferences/imageIllustraion1.svg',
            value: 'HAS_IMAGE',
          },
          {
            name: 'Avoid images',
            img: '/assets/img/overnight-prezentations/preferences/imageIllustrations2.svg',
            value: 'NO_IMAGE',
          },
        ],
        colorPreferences: [
          {
            name: 'Use more color',
            img: '/assets/img/overnight-prezentations/preferences/colorPreference1.svg',
            value: 'HAS_COLOR',
          },
          {
            name: 'Use less color',
            img: '/assets/img/overnight-prezentations/preferences/colorPreference2.svg',
            value: 'NO_COLOR',
          },
        ],
        illustrationPreferences: [
          {
            name: 'Include illustrations',
            img: '/assets/img/overnight-prezentations/preferences/illustraionsPreference1.svg',
            value: 'HAS_ILLUSTRATION',
          },
          {
            name: 'Skip illustrations',
            img: '/assets/img/overnight-prezentations/preferences/illustrationPreference2.svg',
            value: 'NO_ILLUSTRATION',
          },
        ],
        dataPreferences: [
          {
            name: 'Prefers charts',
            img: '/assets/img/overnight-prezentations/preferences/dataPreferance1.svg',
            value: 'HAS_DATA',
          },
          {
            name: 'Prefers tables',
            img: '/assets/img/overnight-prezentations/preferences/dataPreference2.svg',
            value: 'NO_DATA',
          },
        ],
      },
      selectedPreference: {
        imagePreferences: '',
        colorPreferences: '',
        illustrationPreferences: '',
        dataPreferences: '',
      },
      audiencePreference: {
        imagePreferences: '',
        colorPreferences: '',
        illustrationPreferences: '',
        dataPreferences: '',
      },
      nameRules: [(v) => !!v || 'Prezentation name is required'],
      rationaleRules: [(v) => !!v || 'Business rationale is required'],
      otherForumRules: [(v) => !!v || 'Please provide the other forum name'],
      audience: null,
      presentTypeList: [],
      presentAllTypeList: [],
      presentationType: null,
      search: '',
      customStorylineName: '',
      localFiles: [],
      sharedAccess: false,
      addTemplateBy: 'deck',
      templateSelected: null,
      templatesList: [],
      sharedLinks: '',
      instructions: '',
      ownTemplate: null,
      loading: false,
      isReqSubmitted: false,
      loadingAudPrefs: false,
      allowedUploadTypes: [
        '.ppt',
        '.pptx',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.txt',
        '.pdf',
        '.jpg',
        '.png',
      ],
      backPos: '40%',
      templateSearchTerm: '',
      searchWithTextBoxActive: false,
      allThemesFetched: [],
      themesFilteredWithSearch: [],
      themesFrames: '1fr 1fr 1fr',
      forum: '',
      sensitiveFieldsForums: {
        external: [
          {
            name: 'Hospitals',
          },
          {
            name: 'Payers',
          },
          {
            name: 'Conference',
          },
          {
            name: 'Other',
          },
        ],
        internal: [
          {
            name: 'C-suite',
          },
          {
            name: 'Director',
          },
          {
            name: 'SVP',
          },
          {
            name: 'Senior Manager',
          },
          {
            name: 'VP',
          },
          {
            name: 'Manager or below',
          },
          {
            name: 'Senior Director',
          },
          {
            name: 'Other',
          },
        ],
      },
      forumExt: '',
      forumInt: '',
      otherForumName: '',
      businessRationale: '',
    };
  },
  components: {
    AudienceSelectorTypesense,
    // FingerprintMenu,
    ConfirmationDialog,
    StorylineDropdown,
    QuickSelectionCntr,
  },
  watch: {
    async currentTheme(newVal, oldVal) {
      if (newVal.code === oldVal?.code) {
        return;
      }
      this.templatesList = this.sortedThemeArray();
    },
    async startLoadingSlidesFlag(val) {
      if (val) {
        this.audience = { defaultAudience: 'SELF' };
      }
    },
    themes(val) {
      if (val && val.length > 0) {
        this.allThemesFetched = [...val];
        this.themesFilteredWithSearch = [...val];
      }
    },
    addTemplateBy(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== 'company') {
        this.templateSelected = null;
        this.allThemesFetched = [...this.themes];
        this.themesFilteredWithSearch = [...this.themes];
      }
    },
    leftSideBarStatus(val) {
      if (!val) {
        this.showQuickSelection = false;
        this.toolType = '';
      }
    },
  },
  async beforeMount() {
    if (this.$route?.query?.origin !== '') {
      this.tabOrigin = this.$route.query.origin;
    }
    if (this.currentTheme) {
      this.templatesList = this.sortedThemeArray();
    }
    if (this.startLoadingSlidesFlag) {
      this.audience = { defaultAudience: 'SELF' };
    }
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setLimitData',
      'setUpgradePopup',
    ]),
    handleQuickSelection(action) {
      EventBus.$emit('MINIMIZE_LEFTNAV');
      if (action && (this.toolType !== action || !this.toolType)) {
        this.toolType = action;
        this.showQuickSelection = true;
      } else if (this.toolType === action || !action) {
        this.toolType = null;
        this.showQuickSelection = false;
      }
    },
    handleStorylineSelect(storyline) {
      if (storyline && storyline.type !== 'Other') {
        this.presentationType = storyline;
      }
    },
    async generateImageUrls() {
      try {
        const { data } = await getUserCompanyInfo(
          this.currentUser.company.name.toLowerCase(),
        ).catch(() => {});
        const { data: fingerprintSlides } = await getFingerprintSlides(
          snakeCase(Object.keys(data)[0]),
        ).catch(() => {});
        const sortedSlides = chunk(orderBy(fingerprintSlides, 'unique_id'), 1);
        sortedSlides.forEach((slides, index) => {
          getFileURL(
            this.currentUser.user.cognitoID,
            slides[0].landscape,
            this.currentUser.userIp,
          ).then((url) => {
            if (index === 0) {
              this.preferences.imagePreferences[0].img = url;
            } else if (index === 1) {
              this.preferences.imagePreferences[1].img = url;
            } else if (index === 2) {
              this.preferences.colorPreferences[0].img = url;
            } else if (index === 3) {
              this.preferences.colorPreferences[1].img = url;
            } else if (index === 4) {
              this.preferences.illustrationPreferences[0].img = url;
            } else if (index === 5) {
              this.preferences.illustrationPreferences[1].img = url;
            } else if (index === 10) {
              this.preferences.dataPreferences[0].img = url;
            } else if (index === 11) {
              this.preferences.dataPreferences[1].img = url;
            }
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    handleStartEventTrigger() {
      if (this.eventStart === null) {
        this.eventStart = new Date();
      }
    },
    handleLocalSearchTemplates: debounce((e, thisInstance) => {
      if (e === '') {
        thisInstance.themesFilteredWithSearch = [
          ...thisInstance.allThemesFetched,
        ];
      } else {
        const results = [...thisInstance.allThemesFetched].filter(
          (itm) =>
            itm.name.trim().toLowerCase().includes(e.trim().toLowerCase()) ||
            itm.code.trim().toLowerCase().includes(e.trim().toLowerCase()),
        );
        thisInstance.themesFilteredWithSearch = [...results];
      }
    }, 500),
    handleTemplateOpSearchChange(e) {
      this.templateSearchTerm = e;
      this.handleLocalSearchTemplates(this.templateSearchTerm, this);
    },
    handleStepTwoNextClick() {
      this.storylineType = this.presentationType;
      this.stepperValue = 3;
      window.scrollTo({ top: 0 });
      if (this.tabOrigin !== '' && this.tabOrigin) {
        trackOvernightPrezentationsEvents.OPOverviewSubmitNewRequestNextNext(
          this.currentUser,
          {
            [TD_PREZNAME]: this.opName,
            [TD_PREZTYPE]: this.presentationType
              ? this.presentationType.type
              : NA,
            [TD_PLACEMENT]: this.tabOrigin,
            [TD_COMMON_COLUMN_NAME]: TD_SHARED_FILE_ACCESS,
            [TD_AUD]: this.audience.email,
            [TD_COMMON_COLUMN_VALUE]: this.sharedAccess
              ? TD_CHECKED
              : TD_UNCHECKED,
            [TD_INSTRUCTIONS]: this.instructions,
          },
        );
      } else {
        trackOvernightPrezentationsEvents.OPNewRequestNextNext(
          this.currentUser,
          {
            [TD_PREZNAME]: this.opName,
            [TD_PREZTYPE]: this.presentationType
              ? this.presentationType.type
              : NA,
            [TD_COMMON_COLUMN_NAME]: TD_SHARED_FILE_ACCESS,
            [TD_AUD]: this.audience.email,
            [TD_COMMON_COLUMN_VALUE]: this.sharedAccess
              ? TD_CHECKED
              : TD_UNCHECKED,
            [TD_INSTRUCTIONS]: this.instructions,
          },
        );
      }
    },
    changeTargetForum(option) {
      this.handleStartEventTrigger();
      if (option === 'External') {
        this.forumInt = '';
      } else if (option === 'Internal') {
        this.forumExt = '';
      }
      this.otherForumName = '';
      this.forum = option;
    },
    isForumValueSelected() {
      if (this.forum === 'External') {
        if (this.forumExt === '') {
          return false;
        }
        return true;
      }
      if (this.forum === 'Internal') {
        if (this.forumInt === '') {
          return false;
        }
        return true;
      }
      return false;
    },
    clearTextFieldSearch() {
      this.templateSearchTerm = '';
      this.handleTemplateOpSearchChange(this.templateSearchTerm);
    },
    getFileItemsHeight(file) {
      if (file.fileName) {
        if (file.fileName.length <= 40) {
          return '32px';
        }
        return 'auto';
      }
      return file.name.length <= 40 ? '32px' : 'auto';
    },
    handleFirstStepNextClick() {
      this.stepperValue = 2;
      this.showFingerprint = false;
      window.scrollTo({ top: 0 });
      if (this.tabOrigin !== '' && this.tabOrigin) {
        trackOvernightPrezentationsEvents.OPOverviewSubmitNewRequestNext(
          this.currentUser,
          {
            [TD_PREZNAME]: this.opName,
            [TD_PREZTYPE]: this.presentationType
              ? this.presentationType.type
              : NA,
            [TD_AUD]: this.audience.email,
            [TD_PLACEMENT]: this.tabOrigin,
          },
        );
      } else {
        trackOvernightPrezentationsEvents.OPNewRequestNext(this.currentUser, {
          [TD_PREZNAME]: this.opName,
          [TD_PREZTYPE]: this.presentationType
            ? this.presentationType.type
            : NA,
          [TD_AUD]: this.audience.email,
        });
      }
    },
    async findAudiencePreferences(audience) {
      this.loadingAudPrefs = true;
      await getFingerprint(audience?.email, audience?.audienceType)
        .then((resp) => {
          const fpResponses = resp?.fingerprintResponse;
          // image preference
          if (fpResponses[0] && fpResponses[0].split(',')[1] === 'leftImage') {
            this.selectedPreference.imagePreferences = 'HAS_IMAGE';
          } else {
            this.selectedPreference.imagePreferences = 'NO_IMAGE';
          }

          // colour preference
          if (fpResponses[1] && fpResponses[1].split(',')[1] === 'leftImage') {
            this.selectedPreference.colorPreferences = 'HAS_COLOR';
          } else {
            this.selectedPreference.colorPreferences = 'NO_COLOR';
          }

          // illustration preference
          if (fpResponses[2] && fpResponses[2].split(',')[1] === 'leftImage') {
            this.selectedPreference.illustrationPreferences =
              'HAS_ILLUSTRATION';
          } else {
            this.selectedPreference.illustrationPreferences = 'NO_ILLUSTRATION';
          }

          // data preference
          if (fpResponses[5] && fpResponses[5].split(',')[1] === 'leftImage') {
            this.selectedPreference.dataPreferences = 'HAS_DATA';
          } else {
            this.selectedPreference.dataPreferences = 'NO_DATA';
          }
          // deep copy of audience
          this.audiencePreference = JSON.parse(
            JSON.stringify(this.selectedPreference),
          );
        })
        .catch((err) => {
          console.log(err);
          this.selectedPreference = {
            imagePreferences: '',
            colorPreferences: '',
            illustrationPreferences: '',
            dataPreferences: '',
          };
        });
      this.loadingAudPrefs = false;
    },
    removeFile(i) {
      this.localFiles.splice(i, 1);
    },
    closeFingerprintShow() {
      this.showFingerprint = false;
    },
    toggleShowFingerprint() {
      trackOvernightPrezentationsEvents.OPNewRequestViewPreferencesClick(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_VALUE]: this.showFingerprint
            ? TD_CHECKED
            : TD_UNCHECKED,
        },
      );
      this.showFingerprint = !this.showFingerprint;
    },
    // selectPreference(type, item) {
    //   console.log(item);
    //   if (type === 'image') {
    //     this.selectedPreference.imagePreferences = { ...item };
    //     this.preferences.imagePreferences.map((ele) => {
    //       ele.active = item.name === ele.name;
    //       return ele;
    //     });
    //   }
    //   if (type === 'color') {
    //     this.selectedPreference.colorPreferences = { ...item };
    //   }
    //   if (type === 'illustration') {
    //     this.selectedPreference.illustrationPreferences = { ...item };
    //   }
    //   if (type === 'data') {
    //     this.selectedPreference.dataPreferences = { ...item };
    //   }
    // },
    handleClose() {
      this.templateSelected = null;
    },
    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {},
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    isCurrentSelected(id) {
      return this.currentTheme.code === id;
    },
    handleClickTheme(theme) {
      this.templateSelected = theme;
    },
    fileAlreadyUploaded(uploadedFiles, file) {
      return uploadedFiles.find((fl) => fl.fileName === file.name);
    },
    async previewFiles(event) {
      // this.localFiles = [...event.target.files];
      const newFilesToUpload = [...event.target.files].filter(
        (fle) => !this.fileAlreadyUploaded(this.localFiles, fle),
      );
      if (newFilesToUpload.length > 0) {
        this.$modal.show(
          Upload,
          {
            filess: [...newFilesToUpload],
            updateLocalFiles: this.updateLocalFiles,
          },
          {
            name: 'upload',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '45%',
              'max-width': '600px',
              'border-radius': '12px',
              overflow: 'auto',
            },
            clickToClose: false,
          },
        );
        // event.target.value = '';
      }
      event.target.value = '';
    },
    updateLocalFiles(val) {
      this.localFiles = [...this.localFiles, ...val];
    },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/op/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },
    getForumTypeSelected() {
      if (this.forum === 'External') {
        if (this.forumExt !== 'Other') {
          return this.forumExt;
        }
        return `Other - ${this.otherForumName}`;
      }
      if (this.forumInt !== 'Other') {
        return this.forumInt;
      }
      return `Other - ${this.otherForumName}`;
    },
    async submitRequest() {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
        })
        .catch((error) => console.log(error));
      if (
        this.limitsInfoObj?.op?.access_level === 'full_access' ||
        !this.limitsInfoObj?.op?.isLimitCrossed
      ) {
        postFeatureUsage({ feature: 'op' })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response);
            }
          })
          .catch((error) => console.log(error));
        this.loading = true;
        const body = {
          name: this.opName,
          isAddedPrez: this.addToLibraryEnabled
            ? this.isAddedToPrezentation
            : false,
          audienceId:
            this.audience.audienceType === 'private_audience'
              ? this.audience.uuid
              : this.audience.email,
          audienceType: this.audience ? this.audience.audienceType : null,
          isCustomStoryline:
            (this.storylineType &&
              this.storylineType.type.toLowerCase() === 'other') ||
            false,
          templateId:
            this.addTemplateBy === 'company'
              ? this.templateSelected.code
              : null,
          personalPreference: [
            {
              audience: this.audiencePreference,
              selected: this.selectedPreference,
            },
          ],
          attachment: {},
          instructions: [this.instructions.replace(/\n/g, '<br />')],
          targetForumType: this.isOpSensitiveFieldsEnabled
            ? this.forum
            : undefined,
          targetForum: this.isOpSensitiveFieldsEnabled
            ? this.getForumTypeSelected()
            : undefined,
          businessRationale: this.isOpSensitiveFieldsEnabled
            ? this.businessRationale
            : undefined,
        };
        if (this.sharedLinks) {
          body.attachment.links = this.sharedLinks
            .split('\n')
            .filter((ele) => ele !== '')
            .map((ele) => ({
              linkUrl: ele,
              isShared: true,
            }));
        }
        if (this.storylineType) {
          body.storyline =
            this.storylineType.type.toLowerCase() === 'other'
              ? this.customStorylineName
              : this.storylineType.type;
        }
        if (this.localFiles.length) {
          body.attachment.files = [...this.localFiles];
          // }
        }
        if (this.addTemplateBy === 'own') {
          // upload the template file
          const identifier = uuidv4();
          const template = {
            file: this.ownTemplate,
            name: removeSpecialCharacters(this.ownTemplate.name),
            requestIdentifier: identifier,
          };

          await uploadChunks(template, 'op');
          const resp = await uploadOPFile(identifier, template.name, 'op');

          if (resp) {
            body.attachment.template = {
              fileName: this.ownTemplate.name,
              type: 's3-template',
              s3Bucket: process.env.VUE_APP_EXTERNAL_SERVICE_BUCKET,
              s3Prefix: resp.data?.s3Prefix,
            };
          }
        }
        console.log(body);
        submitOPRequest(body).then(async (resp) => {
          console.log(resp);
          const currUser = await usersApi.methods.getUserInfo();
          this.setCurrentUser(currUser);
          this.loading = false;
          this.isReqSubmitted = true;
        });
        let numberOfSlides = NA;
        if (body.attachment.files) {
          if (body.attachment.files.length > 1) {
            const filesSize = body.attachment.files.map((file) => file.size);
            numberOfSlides = `(${filesSize.join()})`;
          } else if (body.attachment.files.length === 1) {
            numberOfSlides = body.attachment.files[0].size.toString();
          }
        }

        console.log('numberOfSlides', numberOfSlides);
        if (this.tabOrigin !== '' && this.tabOrigin) {
          trackOvernightPrezentationsEvents.OPOverviewSubmitNewRequestNextNextSubmit(
            this.currentUser,
            {
              [TD_PREZNAME]: this.opName,
              [TD_AUD]: this.audience.email,
              [TD_PREZTYPE]: this.presentationType
                ? this.presentationType.type
                : NA,
              [TD_TEMPLATE]: this.templateSelection[this.addTemplateBy],
              [TD_PLACEMENT]: this.tabOrigin,
              [TD_COMMON_COLUMN_NAME]: TD_ADD_COPY_FINAL_DECK,
              /* eslint-disable no-nested-ternary */
              [TD_COMMON_COLUMN_VALUE]: this.addToLibraryEnabled
                ? this.isAddedToPrezentation
                  ? TD_CHECKED
                  : TD_UNCHECKED
                : false,
              [TD_INSTRUCTIONS]: this.instructions,
              [TD_TIME_SPENT_IN_SECS]: (new Date() - this.eventStart) / 1000,
              [TD_NUMBEROFSLIDES]: numberOfSlides,
            },
          );
        } else {
          trackOvernightPrezentationsEvents.OPNewRequestNextNextSubmit(
            this.currentUser,
            {
              [TD_PREZNAME]: this.opName,
              [TD_PREZTYPE]: this.presentationType
                ? this.presentationType.type
                : NA,
              [TD_AUD]: this.audience.email,
              [TD_TEMPLATE]: this.templateSelection[this.addTemplateBy],
              [TD_COMMON_COLUMN_NAME]: TD_ADD_COPY_FINAL_DECK,
              /* eslint-disable no-nested-ternary */
              [TD_COMMON_COLUMN_VALUE]: this.addToLibraryEnabled
                ? this.isAddedToPrezentation
                  ? TD_CHECKED
                  : TD_UNCHECKED
                : false,
              [TD_INSTRUCTIONS]: this.instructions,
              [TD_TIME_SPENT_IN_SECS]: (new Date() - this.eventStart) / 1000,
              [TD_NUMBEROFSLIDES]: numberOfSlides,
            },
          );
        }
        trackTimeSpentEvents.OPSubmittingANewRequest(this.currentUser, {
          [TD_EVENT_START]: this.eventStart
            .toUTCString()
            .replaceAll(',', '')
            .replace('GMT', ''),
          [TD_EVENT_END]: new Date()
            .toUTCString()
            .replaceAll(',', '')
            .replace('GMT', ''),
          [TD_TIME_SPENT]: (new Date() - this.eventStart) / 1000,
        });
        this.eventStart = null;
      } else {
        this.setUpgradePopup({
          upgradePopup: true,
          popupType: 'op-prezentation',
        });
      }
    },
    submitAnother() {
      this.reset();
      trackOvernightPrezentationsEvents.OPSubmitAnotherRequest(
        this.currentUser,
        {
          [TD_PLACEMENT]: this.tabOrigin === '' ? TD_NEW_REQUEST : TD_OVERVIEW,
        },
      );
    },
    async reset() {
      this.stepperValue = 1;
      this.opName = '';
      this.searchTerm = '';
      this.audience = { defaultAudience: 'SELF' };
      this.showFingerprint = true;
      // this.presentTypeList = [];
      this.presentationType = null;
      this.search = '';
      this.customStorylineName = '';
      this.localFiles = [];
      this.sharedAccess = false;
      this.addTemplateBy = 'deck';
      this.templateSelected = null;
      this.templatesList = [];
      this.sharedLinks = '';
      this.instructions = '';
      this.ownTemplate = null;
      this.loading = false;
      this.isReqSubmitted = false;
    },
    sortedThemeArray() {
      let finalArray = [];
      finalArray = this.themes;
      finalArray.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
      return finalArray;
    },
    isValidHttpUrl(string) {
      const linksArr = string.split('\n').filter((ln) => ln !== '');
      for (let i = 0; i < linksArr.length; i++) {
        let url = linksArr[i];
        try {
          url = new URL(url);
          if (url.protocol === 'http:' || url.protocol === 'https:') {
            console.log(url.protocol);
          } else {
            return false;
          }
        } catch (_) {
          return false;
        }
      }
      return true;
    },
    getStorylinesAsPerAudience(aud) {
      this.presentationType = null;
      if (DEDUCTIVE_PRINTS.includes(aud.fingerPrint.toLowerCase())) {
        this.audienceStoryPref = 'deductive';
      } else if (INDUCTIVE_PRINTS.includes(aud.fingerPrint.toLowerCase())) {
        this.audienceStoryPref = 'inductive';
      }
      // this.presentTypeList = this.presentAllTypeList.filter(
      //   (item) => item.storyType === storyType,
      // );
    },
    onDialogClose() {
      this.showDialog = false;
    },
    onDialogConfirm() {
      this.showDialog = false;
      this.openAddCollegue();
    },
    openAddCollegue() {
      this.$modal.show(
        AddCollegueDetail,
        {
          origin: 'overnight-prezentations',
        },
        AddNewAudienceModalProps,
      );
    },
    openRemindModal(currentAudience, trackingInfo) {
      this.$forceUpdate();
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: currentAudience.audienceType === 'user',
          origin: 'overnight-prezentations',
          metaDataForTracking: trackingInfo,
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    handleAddAudience(val) {
      this.showDialog = true;
      if (val && !val.fingerPrint) {
        const additionalTrackingInfo = {};
        this.openRemindModal(val || {}, additionalTrackingInfo);
      }
    },
    async handleAudienceSelect(val) {
      if (val.fingerPrint) {
        await this.findAudiencePreferences(this.audience);
        this.getStorylinesAsPerAudience(this.audience);
      }
    },
    handleManageStorylines() {
      this.$router.push(`/profile?tab=storylines`);
    },
    async handleCustomSelectClick() {
      this.search = 'Other';
    },
    handleHowtoShareAccess() {
      trackOvernightPrezentationsEvents.OPHowToShareAccessClick(
        this.currentUser,
      );
      this.$modal.show(
        HowToShareAccess,
        {},
        {
          name: 'HowToShareAccess',
          classes: 'v-application',
          // width: '1330px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    // isFilesLoaded() {
    //   let isLoaded = false;
    //   for (let i = 0; i < this.localFiles.length; i++) {
    //     if (this.localFiles[i].loading) {
    //       isLoaded = false;
    //     } else {
    //       isLoaded = true;
    //     }
    //   }
    //   return isLoaded;
    // },
    async getStoryLinesForBuild() {
      this.presentAllTypeList = [];
      await Promise.all([
        getStorylines({ addInternal: true, storyType: 'inductive' }),
        getStorylines({ addInternal: true, storyType: 'deductive' }),
      ])
        .then((res) => {
          res.forEach((response, i) => {
            response.data?.items.forEach((storyline) => {
              this.presentAllTypeList.push({
                ...storyline,
                type: storyline.type === 'Custom' ? 'Other' : storyline.type,
                storyType: i === 1 ? 'deductive' : 'inductive',
              });
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adjustItemsOnResize() {
      if (window.innerWidth > 800 && window.innerWidth <= 830) {
        this.backPos = '0%';
        this.themesFrames = '1fr';
      } else if (window.innerWidth > 830 && window.innerWidth <= 900) {
        this.backPos = '2%';
        this.themesFrames = '1fr';
      } else if (window.innerWidth > 901 && window.innerWidth <= 1050) {
        this.backPos = '10%';
        this.themesFrames = '1fr 1fr';
      } else if (window.innerWidth > 1050 && window.innerWidth <= 1200) {
        this.backPos = '15%';
        this.themesFrames = '1fr 1fr';
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1400) {
        this.backPos = '20%';
        this.themesFrames = '1fr 1fr';
      } else if (window.innerWidth > 1400 && window.innerWidth <= 1700) {
        this.backPos = '25%';
        this.themesFrames = '1fr 1fr 1fr';
      } else if (window.innerWidth > 1700 && window.innerWidth <= 1900) {
        this.backPos = '28%';
        this.themesFrames = '1fr 1fr 1fr';
      } else if (window.innerWidth > 1900 && window.innerWidth <= 2700) {
        this.backPos = '32%';
        this.themesFrames = '1fr 1fr 1fr';
      } else {
        this.backPos = '40%';
        this.themesFrames = '1fr 1fr 1fr';
      }
    },
  },
  computed: {
    ...mapGetters('users', ['getOPAccessLevel']),
    ...mapState('users', [
      'themes',
      'currentTheme',
      'startLoadingSlidesFlag',
      'currentUser',
      'limitsInfoObj',
      'leftSideBarStatus',
    ]),
    getCurrentAudienceName() {
      return this.audience?.fullName || '';
    },
    disableNewReq() {
      return (
        this.getOPAccessLevel === 'restricted_access' &&
        this.limitsInfoObj?.op?.isLimitCrossed
      );
    },
    disableNextButton() {
      if (this.stepperValue === 1) {
        if (this.isOpSensitiveFieldsEnabled === true) {
          return (
            this.opName === '' ||
            !this.audience ||
            this.forum === '' ||
            this.businessRationale === '' ||
            (this.forum === 'External' && this.forumExt === '') ||
            (this.forum === 'Internal' && this.forumInt === '') ||
            (this.forum === 'External' &&
              this.forumExt === 'Other' &&
              this.otherForumName === '') ||
            (this.forum === 'Internal' &&
              this.forumInt === 'Other' &&
              this.otherForumName === '')
          );
        }
        return !(
          this.opName &&
          this.audience &&
          (!this.presentationType ||
            this.presentationType.text !== 'Other' ||
            this.customStorylineName.trim().length)
        );
      }
      if (this.stepperValue === 2) {
        return (
          (this.localFiles.length <= 0 &&
            !this.instructions &&
            this.sharedLinks.length <= 0) ||
          (this.sharedLinks.length > 0 &&
            (!this.isValidHttpUrl(this.sharedLinks) ||
              this.sharedAccess === false))
        );
      }
      return true;
    },
    isOpSensitiveFieldsEnabled() {
      const sensitiveFeature = this.currentUser.features.find(
        (ft) => ft.feature_name === 'overnight-prezentations-sensitive-fields',
      );
      return sensitiveFeature && sensitiveFeature.enabled === true;
    },

    // Property to get whether add to library is enabled
    addToLibraryEnabled() {
      const addToLibFeature = this.currentUser.features.find(
        (ft) => ft.feature_name === 'add_to_library',
      );
      if (addToLibFeature) {
        return addToLibFeature.enabled;
      }
      return false;
    },
  },
  async mounted() {
    this.handleQuickSelection('audience');
    await this.getStoryLinesForBuild();
    if (this.audience) {
      this.getStorylinesAsPerAudience(this.audience);
    }
    this.adjustItemsOnResize();
    this.generateImageUrls();
    window.addEventListener('resize', this.adjustItemsOnResize);
    // for (let ele of this.preferences) {
    //   ele = ele.map((item) => {
    //     for (const val of this.selectPreference) {
    //       item.active = val.name === item.name;
    //     }
    //     return item;
    //   });
    // }
    if (
      this.limitsInfoObj?.op?.isLimitCrossed &&
      this.limitsInfoObj?.op?.access_level === 'restricted_access'
    ) {
      this.$router.push({
        path: `/home/overnight-prezentations?tab=overview`,
        replace: true,
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.adjustItemsOnResize);
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.new-request-container {
  .quick-selection-wrapper {
    height: calc(100vh - 150px);
    overflow: hidden;
  }

  // display: flex;
  min-height: 80vh;
  .quick-panel-active {
    width: calc(100% - 550px) !important;
  }
  .quick-panel {
    width: 550px !important;
  }
  .fingerprint-show-active {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    margin-top: 0px;
    height: calc(100% - 140px);
    z-index: 1;
  }
  .new-request-content {
    margin: 0 auto !important;
    padding-bottom: 30px;
    max-width: 70%;
  }

  .submit-request-content {
    display: flex;
    flex-direction: column;
    width: 990px;
    margin: 30px auto;
    align-items: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
    gap: 24px;

    .heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.02em;
    }
  }

  .define-context-header {
    font-size: 1.86vw;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 5px;
    text-align: left;
  }

  .template-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 24px 0px 0px 0px;
  }

  .template-radio {
    width: 50%;
    ::v-deep .v-radio {
      .v-label {
        color: #000000;
      }
    }
  }

  .define-context-subheader {
    font-family: 'Lato' !important;
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 20px 0;
    text-align: left;
  }
  .preference-container {
    min-height: 80vh;
    width: 100%;
    .upload-file {
      width: 294px;
      margin-left: 20px;
      // height: 20px;
    }
    .search-template {
      padding: 0px 4px;
      .suggested-title {
        color: #7b7b7b;
        margin: 0px 0px 10px 0px;
      }
      .searched-theme {
        height: 130px;
        width: 350px;
        padding: 8px;
        position: relative;
        border-radius: 10px;
        box-shadow: 1px 1px 4px #21a7e0, -1px -1px 4px #21a7e0;
        margin: 0px 0px 24px 0px;
        .close-icon {
          font-size: 15px;
          position: absolute;
          top: 5px;
          right: 8px;
          cursor: pointer;
        }
        .template-title {
          padding: 0px 0px 5px 0px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          height: 20%;
          width: 100%;
          /* identical to box height */

          letter-spacing: 0.01em;

          /* Color Styles (Old)/Dark */

          color: #212121;
        }
        .thumbnailsRow {
          display: flex;
          width: 100%;
          height: 80%;
          .available-theme-img {
            padding: 4px;
            width: 50%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
                -1px -1px 4px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      .suggested-theme {
        cursor: pointer;
        height: 130px;
        padding: 8px;
        border-radius: 10px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        .template-title {
          padding: 0px 0px 5px 0px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.01em;
          color: #212121;
          height: 20%;
          width: 100%;
        }
        .thumbnailsRow {
          display: flex;
          height: 80%;
          width: 100%;
          .available-theme-img {
            padding: 4px;
            width: 50%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
                -1px -1px 4px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }

      .template-grid {
        width: 100%;
        padding: 4px;
        display: grid;
        flex-wrap: wrap;
        max-height: 390px;
        overflow-y: scroll;
        grid-row-gap: 16px;
        grid-column-gap: 16px;
      }
      .noTemplatesFound {
      }
      .templates-op-search-bar {
        width: 36%;
        margin: 0px 0px 30px 0px;
        .templates-op-search-bar__input {
          background-color: #fff;
          border: 1px solid transparent;
          border-radius: 24px;
          box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
          display: flex;
          font-size: 14px;
          height: 40px !important;
          padding: 2px 14px 8px 20px;
          z-index: 3;
          ::v-deep .v-input__slot::before {
            border-style: none !important;
            display: none;
          }
          ::v-deep .v-input__icon {
            margin-right: -8px;
          }
        }
      }
    }
    .audience-preference {
      .reminder-heading {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        color: #000000;
      }
      .reminder-cont {
        padding-top: 15px;
        position: relative;
        width: 378px;
        .reminder-heading2 {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
        .info-icon {
          font-size: 20px;
          position: absolute;
          margin-left: 10px;
        }
      }
    }
    .audience-selector {
      width: 520px;
      margin-top: 0px;
    }
    .add-to-prezentations {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      .v-icon {
        padding: 0px 0px 0px 6px;
      }
      ::v-deep .v-input {
        .v-input__control {
          .v-input__slot {
            .v-label {
              color: #000000;
            }
          }
        }
      }
    }
    .custom-preference-container {
      margin: 24px 0px 0px 0px;
      .pref-heading1 {
        width: 100%;
        // height: 36px;

        /* Body */

        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        /* or 21px */

        display: flex;
        align-items: flex-end;

        color: #000000;
        margin: 0px 0px 24px 0px;
        // margin: 0px 0px 36px 0px;
      }
      .custom-preferencess {
        display: flex;
        justify-content: space-between;
        max-height: 377px;
        .single-preference {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          margin: 0px 5px;
          max-width: 22.5%;
          // height: 355.99px;
          .single-pref-heading {
            width: 100%;
            height: 36px;

            /* Field Name */

            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            gap: 16px;

            /* Color Styles (Old)/Dark */

            color: #212121;
          }
          .single-sub-heading {
            width: 100%;
            height: 32px;

            /* Body */

            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            /* or 21px */
            margin-bottom: 5px;
            display: flex;
            align-items: flex-end;

            /* Color Styles (Old)/Dark */

            color: #212121;
          }

          .image-selected {
            border-radius: 10px;
            width: 100%;
            border: 2.5px solid #21a7e0;
            box-shadow: 0.92328px 0.92328px 3.69312px rgba(0, 0, 0, 0.1),
              -0.92328px -0.92328px 3.69312px rgba(0, 0, 0, 0.1);
            cursor: pointer;
          }
          .image-not-selected {
            border-radius: 10px;
            box-shadow: 0.92328px 0.92328px 3.69312px rgba(0, 0, 0, 0.1),
              -0.92328px -0.92328px 3.69312px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            width: 100%;
          }
        }
        .separator {
          width: 1px;
          background-color: #d9d9d9;
        }
      }
    }
  }
  .files-container {
    width: 100%;
    padding: 0px 4px;
  }
  .context-container {
    padding: 0px 4px;
    .fingerprint-button {
      cursor: pointer;
      position: absolute;
      top: 60px;
      right: 20px;
      .expand-button {
        display: inline-block;
        margin: 0 0 0 400px;
        position: relative;
      }

      .expand-button > * {
        align-items: center;
        border-radius: 25px;
        color: white;
        display: inline-flex;
        justify-content: space-between;
        height: 32px;
        overflow: hidden;
        position: absolute;
        right: 0;
        width: 180px;
        text-decoration: none;
        max-width: 32px;
        bottom: -16px;
        -webkit-transition: max-width 0.5s;
        transition: max-width 0.5s;
      }
      .expand-button-icon,
      .preferences-active {
        margin-right: 0;
        padding: 0px 6px;
        display: flex;
        align-items: center;
      }
    }
    .presentation-name-wrapper {
      margin-bottom: 30px;
      .presentation-name-label {
        font-size: 1.11vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
    .primary-audience-wrapper {
      margin-bottom: 48px;
      .primary-audience-label {
        font-size: 1.11vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .presentation-type-wrapper {
      .presentation-type-label {
        font-size: 1.11vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
    .opSensitiveFieldsContainer {
      margin: 0px 0px 30px 0px;
      .targetForumLabel {
        font-size: 1.11vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
      .forumRadioOptions {
        .forumTypes {
          ::v-deep .v-input {
            margin-top: 12px !important;
            .v-input__control {
              .v-input__slot {
                margin: 0 !important;
                .v-input--radio-group__input {
                  display: flex !important;
                  flex-direction: row !important;
                  .v-radio {
                    margin: 0px 32px 0px 0px;
                    .v-input--selection-controls__input {
                      margin: 0px 4px 0px 0px !important;
                    }
                    .v-label {
                      color: black !important;
                    }
                  }
                }
              }
              .v-messages {
                display: none;
              }
            }
          }
        }
        .externalGroup {
          ::v-deep .v-input {
            margin-top: 12px !important;
            padding: 0px 0px 0px 16px !important;
            .v-input__control {
              .v-input__slot {
                margin: 0 !important;
                .v-input--radio-group__input {
                  .v-radio {
                    .v-input--selection-controls__input {
                      margin: 0px 4px 0px 0px !important;
                    }
                    .v-label {
                      color: black !important;
                    }
                  }
                }
              }
              .v-messages {
                display: none;
              }
            }
          }
          .forumError {
            color: #f70000;
            font-size: 12px;
            line-height: 12px;
            margin: 8px 0px 0px 0px;
          }
        }
        .internalGroup {
          width: 400px;
          ::v-deep .v-input {
            margin-top: 12px !important;
            padding: 0px 0px 0px 16px !important;
            .v-input__control {
              .v-input__slot {
                margin: 0 !important;
                .v-input--radio-group__input {
                  display: flex !important;
                  flex-direction: row !important;
                  flex-wrap: wrap;
                  .v-radio {
                    margin: 0px 12px 8px 0px;
                    min-width: 156px;
                    .v-input--selection-controls__input {
                      margin: 0px 4px 0px 0px !important;
                    }
                    .v-label {
                      color: black !important;
                    }
                  }
                }
              }
              .v-messages {
                display: none;
              }
            }
          }
          .forumError {
            color: #f70000;
            font-size: 12px;
            line-height: 12px;
            margin: 8px 0px 0px 0px;
          }
        }
      }
      .otherForumSelected {
        margin: 4px 0px 0px 0px;
        ::v-deep .v-input {
          margin: 0 !important;
          padding: 0 !important;
          .v-input__control {
            .v-input__slot {
              margin: 0 !important;
            }
            .v-text-field__details {
              margin: 6px 0px 0px 0px;
            }
          }
        }
      }
      .bussinessRationaleField {
        margin: 40px 0px 0px 0px;
        .rationaleLabel {
          display: flex;
          justify-content: space-between;
          .ratioanleLabelText {
            font-size: 1.11vw;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
          }
        }
      }
    }
  }

  .files-desc {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
    margin-bottom: 16px;

    .files-subheader {
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .instructions-container {
    margin-top: 45px;
    .files-subheader {
      margin-bottom: 8px;
    }

    .instructions-area {
      margin-top: 16px;
      border-radius: 16px;
      padding: 0px 2px;
      ::v-deep .v-input__slot {
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
          drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
        box-shadow: none;
        padding: 0px 20px;

        &:focus-within {
          border: 1px solid #21a7e0;
        }

        // .v-text-field__slot {
        //   height: 101px;
        // }

        textarea {
          height: 201px;
        }
      }
    }
  }

  .upload-container {
    display: flex;
    gap: 38px;
    overflow: auto;
    padding: 2px;

    .highlight {
      border: 2px solid #21a7e0;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 64px;
      gap: 12px;
      width: 468px;
      height: 480px;
      background: #ffffff;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 12px;

      .header {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #212121;
      }

      img {
        height: 120px;
      }

      .desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #212121;
      }

      .user-input {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 372px;
        // height: 149px;

        .label {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }

        .share-links {
          width: 100%;
          border-radius: 16px;

          &:focus-within {
            border: 1px solid #21a7e0;
          }

          ::v-deep .v-input__slot {
            filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
              drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
            box-shadow: none;

            // .v-text-field__slot {
            //   height: 101px;
            // }

            textarea {
              height: 101px;
            }
          }
        }
        .file-chips-wrapper {
          padding: 12px;
          height: 113px;
          border-radius: 16px;
          background: white;
          filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
            drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          overflow: auto;
          margin: 0px 0px 24px 0px;

          .file-chip {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 12px;
            // height: 32px;
            gap: 8px;
            position: relative;
            background: #ffffff;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
              -1px -1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            max-width: 96%;
            margin: 4px;
            cursor: default;

            &:hover {
              box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1),
                -1px -1px 8px rgba(0, 0, 0, 0.1);
            }
            .fileNameWrapper {
              max-width: 90%;
              word-wrap: break-word;
            }
            .loading-file {
              width: 90%;
              position: absolute;
              bottom: 0;
            }

            &.size-error {
              border: 1px solid #b00020;
            }

            i {
              cursor: pointer;
            }
          }

          .file-chip-placeholder {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #7b7b7b;
          }
        }
        .v-textarea {
          height: 116px;
        }
      }

      .check-access {
        width: 378px;
        margin-top: 10px;

        .guide-link {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #21a7e0;
          margin-left: 30px;
          margin-top: 4px;
          display: inline-block;
          cursor: pointer;
        }

        ::v-deep .v-input--selection-controls {
          margin-top: 0;
          padding-top: 0;

          .v-input--selection-controls__input {
            margin-right: 6px;
          }
          svg {
            width: 20px;
            height: 20px;
          }

          label {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #000000;
          }
        }
      }
      .check-access-error {
        width: 378px;
        margin-top: 10px;

        .guide-link {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #21a7e0;
          margin-left: 30px;
          margin-top: 4px;
          display: inline-block;
          cursor: pointer;
        }

        ::v-deep .v-input--selection-controls {
          margin-top: 0;
          padding-top: 0;

          .v-input--selection-controls__input {
            margin-right: 6px;
          }
          svg {
            width: 20px;
            height: 20px;
            color: red;
          }

          label {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #000000;
          }
        }
      }

      .file-upload-action {
        input[type='file'] {
          display: none;
        }

        .custom-file-upload {
          padding: 6px 16px;
          height: 31px;
          border: 1.5px solid #21a7e0;
          border-radius: 25px;
          color: #21a7e0;
          cursor: pointer;
        }
      }
    }
  }
}

.prezentaion-list-item--add {
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid #ccc;
  padding: 0px 50px !important;

  // &.add-list-item {
  //   bottom: 49px;
  // }

  .v-list-item__title {
    align-items: center;
    display: flex;
    font-size: 14px;

    .add-icon {
      margin-right: 10px;
    }
  }

  .prez-heading-primary {
    justify-content: center;
    font-size: 14px;
    color: #21a7e0;
  }
}
.option-wrapper {
  display: flex;
  align-items: flex-end;
  .plus-icon {
    font-size: 30px;
    margin-right: 10px;
  }
  .prez-heading,
  .prez-description {
    max-width: 43vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .prez-heading {
    font-size: 14px;
    color: black;
  }
  .prez-description {
    font-size: 12px;
  }
}

.v-list .v-list-item--active {
  color: #21a7e0 !important;
}
.v-list .v-list-item--active .v-icon {
  color: rgba(0, 0, 0, 0.87);
}
.v-list-item--two-line {
  min-height: unset !important;
}

.presentation-type-combobox-wrapper {
  .presentation-type-step2 {
    display: flex;
    ::v-deep .v-input {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 85%;
      font-size: 14x;
    }
  }
  .storyline-type {
    padding-top: 5px;
    font-size: 14px;
    padding-right: 7px;
  }
}
.new-request-contents {
  display: flex;
  height: calc(100vh - 250px) !important;
  overflow-y: scroll;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
}
.stepper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .back-btn {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    margin: 10px;
    color: #21a7e0;
    cursor: pointer;
  }

  .stepper-container {
    width: 400px;

    ::v-deep .v-sheet.v-stepper {
      box-shadow: none !important;

      .v-stepper__header {
        box-shadow: none;

        .v-divider {
          margin: 35px -75px 0 !important;
        }
        .v-stepper__step--complete + .v-divider {
          border-color: $zen-blue-default !important;
        }

        .theme--light.v-stepper .v-stepper__header .v-divider {
          margin-top: 12px !important;
        }

        .v-stepper__header {
          box-shadow: none !important;
        }

        .v-divider {
          margin: 35px -76px 0 !important;
          border-width: 1.5px 0 0 0 !important;
        }
        .v-stepper__step__step {
          background: white !important;
          border: 1.5px solid #21a7e0;

          .v-icon {
            color: #21a7e0;
            padding-right: 1.5px;
            svg {
              height: 23px;
              width: 23px;
            }
          }
        }
      }
    }
  }
  .filter-btn {
    color: #21a7e0;
    border-radius: 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    text-align: center;
    height: 100%;
    min-width: 100px;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    height: 35px;
    top: 19%;
    right: 3%;
    .aud-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
  .filter-btn-active {
    background-color: #e1f5fb;
  }
}
</style>
